<template>
<v-content>
  <div v-if="!compact && $vuetify.breakpoint.mdAndUp" class="side-menu elevation-3"></div>

  <div class="content-container" :class="{
    'with-bottom-nav':$vuetify.breakpoint.xsOnly,
    'compact': compact,
  }">

    <div v-if="!compact" class="header"
        :class="{
          'mobile':$vuetify.breakpoint.xsOnly,
          'desktop':$vuetify.breakpoint.smAndUp,
        }">
    </div>

    <v-container fluid pa-0 class="main-content banner-container">
      <v-layout row wrap justify-center class="content-area pb-5" :class="{
        'px-3': $vuetify.breakpoint.smAndDown,
      }">

        <v-flex xs12 :class="compact ? '': 'md8'">
          <v-card flat
                  :class="{
                    'product-player-card': $vuetify.breakpoint.smAndUp,
                    'px-5': $vuetify.breakpoint.smAndUp
                  }"
                  :elevation="compact || $vuetify.breakpoint.xsOnly ? 0 : 3">
            <element-view v-if="therapyGroup"
              :therapy-group="therapyGroup"
              :key="therapyGroup.sessionUen"
              isPreview
            ></element-view>
            <v-card-loader v-else></v-card-loader>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</v-content>
</template>

<script>
import elementView from '@/views/products/components/element-view.vue';
import VCardLoader from '@/views/components/loader/v-card-loader.vue';

export default {
  name: 'Preview',
  components: {
    VCardLoader,
    elementView,
  },
  data() {
    return {
      therapyGroup: null,
      compact: false,
    };
  },
  created() {
    window.addEventListener('message', this.receiveTherapyContent);
    window.parent.postMessage({ type: 'therapyContent' }, '*');
    this.$log.debug('MessageEvent listener created, notifying parent');
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveTherapyContent);
  },
  methods: {
    receiveTherapyContent(event) {
      this.$log.debug('MessageEvent received', event);
      if (event.data && event.data.type && event.data.type === 'therapyContent') {
        this.therapyGroup = event.data.content;
        this.compact = event.data.compact || false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.side-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background-image: url('/svg/bg-sidenav.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.product-player-card {
  border-radius: 15px;
}

.header {
  width: 100%;
  border-bottom: 2px solid var(--Starling-Primary-1, #2194DD);

  &.desktop {
    background-color: white;
    height: 64px;
  }

  &.mobile {
    background-color: transparent;
    background-image: url('~@/assets/svg/bg-mobile-banner-2.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position-x: center;
    padding-bottom: 95px;
    position: relative;
    z-index: 10;
  }
}

.rounded-tooltip {
  opacity: 1 !important;
  border-radius: 15px;
}

.content-container {
  z-index: 4;

  &.compact {
    left: 0;
  }

  .main-content {
    position: relative;
    z-index: 0;

    .content-area {
      max-width: var(--content-max-width);
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-content", [
    !_vm.compact && _vm.$vuetify.breakpoint.mdAndUp
      ? _c("div", { staticClass: "side-menu elevation-3" })
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "content-container",
        class: {
          "with-bottom-nav": _vm.$vuetify.breakpoint.xsOnly,
          compact: _vm.compact
        }
      },
      [
        !_vm.compact
          ? _c("div", {
              staticClass: "header",
              class: {
                mobile: _vm.$vuetify.breakpoint.xsOnly,
                desktop: _vm.$vuetify.breakpoint.smAndUp
              }
            })
          : _vm._e(),
        _c(
          "v-container",
          {
            staticClass: "main-content banner-container",
            attrs: { fluid: "", "pa-0": "" }
          },
          [
            _c(
              "v-layout",
              {
                staticClass: "content-area pb-5",
                class: {
                  "px-3": _vm.$vuetify.breakpoint.smAndDown
                },
                attrs: { row: "", wrap: "", "justify-center": "" }
              },
              [
                _c(
                  "v-flex",
                  { class: _vm.compact ? "" : "md8", attrs: { xs12: "" } },
                  [
                    _c(
                      "v-card",
                      {
                        class: {
                          "product-player-card":
                            _vm.$vuetify.breakpoint.smAndUp,
                          "px-5": _vm.$vuetify.breakpoint.smAndUp
                        },
                        attrs: {
                          flat: "",
                          elevation:
                            _vm.compact || _vm.$vuetify.breakpoint.xsOnly
                              ? 0
                              : 3
                        }
                      },
                      [
                        _vm.therapyGroup
                          ? _c("element-view", {
                              key: _vm.therapyGroup.sessionUen,
                              attrs: {
                                "therapy-group": _vm.therapyGroup,
                                isPreview: ""
                              }
                            })
                          : _c("v-card-loader")
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }